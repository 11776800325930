<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="margin-top: 50px">
        <el-col :span="17" :offset="3">
          <el-form
            :model="addForm"
            :rules="rules"
            size="small"
            label-width="120px"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="车牌号码" prop="plate">
                  <el-input v-model="addForm.plate"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="挂车号码" prop="hangPlate">
                  <el-input v-model="addForm.hangPlate"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="车辆所有人" prop="carowner">
                  <el-input v-model="addForm.carowner"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="司机身份证" prop="driverCreticard">
                  <el-input v-model="addForm.driverCreticard"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="司机姓名" prop="driverName">
                  <el-input v-model="addForm.driverName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="行车证号码" prop="carLicencecode">
                  <el-input v-model="addForm.carLicencecode"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="驾驶证号码" prop="driveingLicencecode">
                  <el-input v-model="addForm.driveingLicencecode"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车辆类型" prop="truckType">
                  <el-select style="width: 100%" v-model="addForm.truckType">
                    <el-option
                      v-for="item in truckTypeOptions"
                      :key="item.val"
                      :label="item.val"
                      :value="item.val"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="挂车证号码" prop="hangLicencecode">
                  <el-input v-model="addForm.hangLicencecode"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车主手机号" prop="ownerPhone">
                  <el-input v-model="addForm.ownerPhone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="司机手机号码" prop="driverPhone">
                  <el-input v-model="addForm.driverPhone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="限定载重" prop="truckTypecode">
                  <el-select
                    style="width: 100%"
                    v-model="addForm.truckTypecode"
                  >
                    <el-option
                      v-for="item in truckTypeCodeOptions"
                      :key="item.val"
                      :label="item.val"
                      :value="item.val"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="排放标准" prop="discharge">
                  <el-select style="width: 100%" v-model="addForm.discharge">
                    <el-option
                      v-for="item in dischargeOptions"
                      :key="item.val"
                      :label="item.val"
                      :value="item.val"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <el-row style="margin-left: 10px">
        <el-col style="width: 20%">
          <img-upload
            class="img-upload"
            title="身份证正面"
            v-model="addForm.frontOfIdcard"
          />
        </el-col>
        <el-col style="width: 20%">
          <img-upload
            class="img-upload"
            title="驾驶证正面"
            v-model="addForm.frontDriverLicense"
          />
        </el-col>
        <el-col style="width: 20%">
          <img-upload
            class="img-upload"
            title="行驶证正面"
            v-model="addForm.frontDrivingLicense"
        /></el-col>
        <el-col style="width: 20%">
          <img-upload
            class="img-upload"
            title="挂车证正面"
            v-model="addForm.frontTrailerPermit"
        /></el-col>
        <el-col style="width: 20%">
          <img-upload
            class="img-upload"
            title="排放证明"
            v-model="addForm.emissionCertificate"
        /></el-col>
      </el-row>
      <el-row style="margin-top: 50px">
        <el-col :offset="9">
          <el-button
            size="small"
            class="buttons"
            type="primary"
            v-if="this.$route.query.id"
            @click="confirmUpdate"
            >确认</el-button
          >
          <el-button
            size="small"
            class="buttons"
            type="primary"
            @click="confirmAdd"
            v-else
            >确认</el-button
          >
          <el-button size="small" class="buttons" @click="goBack"
            >取消</el-button
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import areaSelect from "@/components/areaSelect.vue";
import ImgUpload from "@/components/Img-upload.vue";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        plate: "",
        hangPlate: "",
        carowner: "",
        driverCreticard: "",
        driverName: "",
        driveingLicencecode: "",
        truckType: "",
        hangLicencecode: "",
        ownerPhone: "",
        driverPhone: "",
        truckTypecode: "",
        discharge: "",
        frontOfIdcard: "",
        carLicencecode: "",
        frontDriverLicense: "",
        frontDrivingLicense: "",
        frontTrailerPermit: "",
        emissionCertificate: "",
      },
      truckTypeOptions: [],
      truckTypeCodeOptions: [],
      dischargeOptions: [],
      rules: {
        plate: [
          {
            required: true,
            message: "请输入车牌",
            trigger: ["blur", "change"],
          },
        ],
        hangPlate: [
          {
            required: true,
            message: "请输入挂车号码",
            trigger: ["blur", "change"],
          },
        ],
        carowner: [
          {
            required: true,
            message: "请输入车辆所有人",
            trigger: ["blur", "change"],
          },
        ],
        driverCeticard: [
          {
            required: true,
            message: "请输入司机身份证",
            trigger: ["blur", "change"],
          },
        ],
        driverName: [
          {
            required: true,
            message: "请输入司机姓名",
            trigger: ["blur", "change"],
          },
        ],
        carLicencecode: [
          {
            required: true,
            message: "请输入行车证号码",
            trigger: ["blur", "change"],
          },
        ],
        driveingLicencecode: [
          {
            required: true,
            message: "请输入驾驶证号码",
            trigger: ["blur", "change"],
          },
        ],
        truckType: [
          {
            required: true,
            message: "请选择车辆类型",
            trigger: ["blur", "change"],
          },
        ],
        hangLicencecode: [
          {
            required: true,
            message: "请选择行车证号码",
            trigger: ["blur", "change"],
          },
        ],
        ownerPhone: [
          {
            required: true,
            message: "请输入车主手机号",
            trigger: ["blur", "change"],
          },
        ],
        driverPhone: [
          {
            required: true,
            message: "请输入司机手机号码",
            trigger: ["blur", "change"],
          },
        ],
        truckTypecode: [
          {
            required: true,
            message: "请选择限定载重",
            trigger: ["blur", "change"],
          },
        ],
        discharge: [
          {
            required: true,
            message: "请选择排放标准",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  components: {
    areaSelect,
    ImgUpload,
  },
  created() {
    this.getTruckTypeOptions();
    this.getDischargeOptions();
    this.getTruckTypeCodeOptions();
    if (this.$route.query.id) {
      this.$http(
        "api1",
        "/api/biz/TrunkInfo/info/" + this.$route.query.id,
        "get"
      ).then((res) => {
        for (let key in this.addForm) {
          this.addForm[key] = res.data.data[key];
        }
      });
    }
  },
  mounted() {},
  methods: {
    async getTruckTypeOptions() {
      const { data } = await this.$http("api1", "/sys/dic/list", "get", {
        dictionaryType: "车辆类型",
      });
      this.truckTypeOptions = data.data;
    },
    async getDischargeOptions() {
      const { data } = await this.$http("api1", "/sys/dic/list", "get", {
        dictionaryType: "排放标准",
      });
      this.dischargeOptions = data.data;
    },
    async getTruckTypeCodeOptions() {
      const { data } = await this.$http("api1", "/sys/dic/list", "get", {
        dictionaryType: "限重载重",
      });
      this.truckTypeCodeOptions = data.data;
    },
    async confirmAdd() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/TrunkInfo/save",
        "post",
        {
          ...this.addForm,
        }
      );
      if (data.code != 0) {
        return this.$message.error(data.msg);
      } else {
        this.$message.success("操作成功");
        this.goBack();
      }
    },
    async confirmUpdate() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/TrunkInfo/update",
        "post",
        {
          id: this.$route.query.id,
          ...this.addForm,
        }
      );
      if (data.code != 0) {
        return this.$message.error(data.msg);
      } else {
        this.$message.success("操作成功");
        this.goBack();
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
